<template>
  <table class="table table-borderless table-data">
    <template v-if="isNoLicenses">
      <p>There are currently no licences assigned to your account. Please contact your account manager to obtain licences.</p>
    </template>
    <template v-else>
      <thead>
        <tr>
          <th
            v-for="license in licenses"
            :key="license.key"
          >
            {{ license.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-for="license in licenses"
            :key="license.key"
          >
            {{ licenseCount(license.name) }}
            <span v-if="license.available > 0">
              (and {{ license.available }} unused)
            </span>
          </td>
        </tr>
        <!--Padding-->
        <tr v-if="!isProvisioned && (canCancelBusinessLicenses || canCancelProLicenses)">
          <th />
        </tr>
        <tr
          v-if="!isProvisioned && (canCancelBusinessLicenses || canCancelProLicenses)"
        >
          <th>
            {{ 'Cancel spare licenses:' }}
          </th>
          <th v-if="canCancelBusinessLicenses">
            <a
              class="btn-link cursor-pointer"
              @click.prevent="$emit('cancel-business')"
            >
              Cancel Spare Business Licenses
            </a>
          </th>
          <th v-if="canCancelProLicenses">
            <a
              class="btn-link cursor-pointer"
              @click.prevent="$emit('cancel-pro')"
            >
              Cancel Spare Pro Licenses
            </a>
          </th>
        </tr>
      </tbody>
    </template>
  </table>
</template>

<script>
import {userStore} from '@/store/__STORE_user';

export default {
  name: 'LicenseTable',
  props: {
    isProvisioned: {
      type: Boolean,
      default: false,
      required: true,
    },
    licenses: {
      type: Array,
      default: () => [],
      required: true,
    },
    canCancelBusinessLicenses: {
      type: Boolean,
      default: false,
      required: true,
    },
    canCancelProLicenses: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    userList: () => userStore.subUsers,
    authUser: () => userStore.profileData,
    isNoLicenses() {
      return this.licenses.length === 0;
    },
  },
  methods: {
    licenseCount(name) {
      if (!this.isProvisioned) {
        return this.userList.filter((item) => {
          return item.billing_plan && item.billing_plan.name === name;
        }).length;
      }
      const license = this.licenses.find((item) => item.name === name);
      return license.used;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
