<template>
  <div
    id="requestLicensesModal"
    class="modal fade add-width"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div
        class="modal-content"
      >
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Request Licenses
          </h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <license-request-table />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            id="add-card-button"
            class="btn btn-primary"
            @click="submitRequest"
          >
            Request
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {userStore} from '@/store/__STORE_user';
import LicenseRequestTable from '@/components/ProvisionedUsers/LicenseRequestTable.vue';

export default {
  name: 'RequestLicensesModal',
  components: {
    LicenseRequestTable,
  },
  mixins: [],
  props: {},
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    licenses: () => userStore.requestableLicenses,
  },
  created() {
    this.showModal();
  },
  mounted() {
    userStore.getRequestableLicenses();
  },
  methods: {
    showModal() {
      window.setTimeout(() => {
        // eslint-disable-next-line
        $('#requestLicensesModal').modal('show');
      }, 200);
    },
    submitRequest() {
      const request = [];
      const addons = this.licenses.addons;
      const plans = this.licenses.plans;

      for (let i = 0; i < plans.length; i++) {
        if (plans[i].requested > 0) {
          request.push(
              {
                'key': plans[i].key,
                'count': plans[i].requested,
              },
          );
        }
      }

      for (let i = 0; i < addons.length; i++) {
        if (addons[i].requested > 0) {
          request.push(
              {
                'key': addons[i].key,
                'count': addons[i].requested,
              },
          );
        }
      }
      if (request.length > 0) {
        userStore.requestLicenses({'licenses': request})
            .then(() => {
              userStore.getNotifications();
            }).catch(({response}) => {
              //
              this.$toast.error('Failed To Request Licenses');
              this.$toast.success('Licenses requested.');
            });
      } else {
        this.$toast.error('Unable to request licenses, please select at least one license.');
        return;
      }
      this.closeModal();
    },
    closeModal() {
      // eslint-disable-next-line
      $('#requestLicensesModal').modal('hide');
      this.$emit('close-request');
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-content,
  .modal-dialog {
    width: 525px;
  }
  .modal-content {
    margin-top: 100px;
    max-height: calc(90vh - 100px);
    overflow: auto;
  }
</style>
