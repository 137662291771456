<template>
  <div
    id="modal-calendar-integration"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content">
          <div
            class="f-modal-close"
            @click="$emit('close')"
          />
          <div class="modal-popup">
            <div class="modal-popup-content">
              <confirm-dialog-icon />
              <h6 class="mb-2">
                Confirm {{ isBlocked ? 'Unblock' : 'Block' }}
              </h6>

              <div v-if="isBlocked">
                Are you sure you want to unblock this user?
                Please confirm your decision with your password.
              </div>

              <div v-else>
                Are you sure you want to block this user? You will be able to unblock the user later.
                Please confirm your decision with your password.
              </div>

              <div
                class="form-group mt-1 mb-2"
                :class="{'form-error': errors.password}"
              >
                <input
                  id="password-confirm"
                  v-model="password"
                  class="form-control"
                  type="password"
                  placeholder="Your password..."
                >
                <div
                  v-for="(error, index) in errors.password"
                  :key="index"
                  class="form-error-message"
                >
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="text-center">
              <a
                href="#"
                class="btn btn-outline"
                @click.prevent="$emit('close')"
              >Cancel</a>
              <a
                href="#"
                class="btn btn-primary ml-2"
                @click.prevent="blockUser"
              >
                {{ isBlocked ? 'Unblock' : 'Block' }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfirmDialogIcon from './ConfirmDialogIcon';
import {mainStore} from '@/store/__STORE_main';
import {userStore} from '@/store/__STORE_user';

export default {
  components: {
    ConfirmDialogIcon,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    isBlocked: Boolean,
  },
  data() {
    return {
      password: '',
      errors: {},
    };
  },
  created() {
    // eslint-disable-next-line
        $('body').addClass('f-modal-is-open');
  },
  destroyed() {
    // eslint-disable-next-line
        $('body').removeClass('f-modal-is-open');
  },
  methods: {
    blockUser() {
      this.errors = {};

      if (!this.password.length) {
        this.errors = {password: ['Password is required']};
        return;
      }

      mainStore.setLoading();
      const successMsg = this.isBlocked ? 'User unblocked' : 'User blocked';
      userStore.blockUser({id: this.user.id, status: this.isBlocked, password: this.password})
          .then(() => {
            this.password = '';
            mainStore.setStatus('');
            this.$toast.success(successMsg);
            this.$emit('close');
          })
          .catch((err) => {
            this.password = '';
            const data = err.response.data;
            mainStore.setStatus('');
            if (err.response.status === 422) {
              this.errors = data.errors;
              return;
            }
            this.$toast.error('Error');
          });
    },
  },
};
</script>
