<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a>Admin</a>
          </li>
          <li class="breadcrumb-item active">
            User Management
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>User Management</h1>
          </div>
          <div class="section-header-action">
            <router-link
              v-if="canAuthUserAccessAdminFeatures && !isUserProvisioned"
              :to="{ name:'create-user' }"
              class="btn btn-secondary"
            >
              <icon-plus class="mr-2 d-flex gray" />Create a new user
            </router-link>
            <div
              v-else-if="isUserProvisioned"
              class="btn btn-secondary"
              @click.prevent="requestLicenses"
            >
              <icon-plus class="mr-2 d-flex gray" />Request licenses
            </div>
          </div>
        </div>
        <div
          v-if="authUser"
          class="row row-has-panel"
        >
          <div class="col-lg-12">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Licences in use</h3>
                </div>
                <div class="section-panel-header-action">
                  <div
                    v-if="isUserProvisioned"
                    class="section-panel-header-action"
                  >
                    <span
                      class="btn btn-link"
                      @click="reduceLicenses"
                    >
                      <i class="fas fa-minus mr-2" />
                      Request License Reduction
                    </span>
                  </div>
                </div>
              </div>
              <div class="section-panel-body">
                <LicenseTable
                  :is-provisioned="isUserProvisioned"
                  :licenses="licensesForTable"
                  :can-cancel-business-licenses="(businessLicensesCount === 0) && (authUser.spare_business_licenses === 2) && !isUserProvisioned"
                  :can-cancel-pro-licenses="(proLicensesCount === 0) && (authUser.spare_pro_licenses === 2) && !isUserProvisioned"
                  @cancel-business="isShowCancelBusinessLicensesDialog = true"
                  @cancel-pro="showCancelProLicensesDialog = true"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="authUser"
          class="row row-has-panel"
        >
          <div class="col-lg-12">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>All users</h3>
                </div>
                <div
                  v-if="!isUserProvisioned"
                  class="section-panel-header-action"
                >
                  <router-link
                    v-if="canAuthUserAccessAdminFeatures"
                    :to="{ name:'create-user' }"
                    class="btn btn-link"
                  >
                    <icon-plus-primary class="mr-2 d-flex" />
                    Create new user
                  </router-link>
                </div>
                <div
                  v-else
                  class="section-panel-header-action"
                >
                  <router-link
                    v-if="canAuthUserAccessAdminFeatures"
                    :to="{ name:'create-user' }"
                    class="btn btn-primary"
                  >
                    <icon-plus class="mr-2 d-flex gray" />
                    Create new user
                  </router-link>
                </div>
              </div>
              <div class="section-panel-body">
                <div class="table-responsive">
                  <table
                    id="all-users"
                    class="table table-borderless table-data table-hide-last-column"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Remote Expert</th>
                        <th>Phonebook</th>
                        <th>Phone</th>
                        <th>Room ID</th>
                        <th>Licence</th>
                        <th>Addons</th>
                        <th>Role</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="user in userList"
                        :key="user.id"
                      >
                        <td>
                          <i
                            v-if="user.is_pending"
                            v-tooltip="{content: 'Pending invitation', classes: ['tooltip-danger']}"
                            class="fa fa-info-circle mx-1 text-danger"
                          />
                          <i
                            v-if="user.is_blocked"
                            v-tooltip="{ content: 'User blocked'}"
                            class="far fa-ban"
                          />
                          {{ user.name }} {{ user.last_name }}
                          <strong v-if="user.id === authUser.id">
                            (you)
                          </strong>
                        </td>
                        <td>{{ user.is_pending ? user.invite_emailed_to : user.email }}</td>
                        <td>{{ user.is_remote_expert ? 'Yes' : 'No' }}</td>
                        <td>{{ user.is_phonebook ? 'Yes' : 'No' }}</td>
                        <td>
                          {{ user.phone }}
                        </td>
                        <td>
                          <span
                            v-if="user.meeting_room && !isSubUserOnNoLicense(user)"
                          >
                            <router-link
                              target="_blank"
                              :to="user.id === authUser.id ?
                                {name: 'host',
                                 params: {webrtcAlias: user.meeting_room.webrtc.alias }}
                                : {name: 'welcome-guest',
                                   params: {
                                     webrtcAlias: user.meeting_room.webrtc.alias,
                                     preloadedMeetingRoom: user.meeting_room
                                   }
                                }"
                            >
                              {{ user.meeting_room.webrtc.alias }}
                            </router-link>
                          </span>
                          <span v-else>
                            N/A
                          </span>
                        </td>
                        <td>
                          <router-link
                            v-if="user.billing_plan && canAuthUserAccessAdminFeatures && !user.is_pending"
                            :to="editPlanAndRoleLink(user)"
                          >
                            {{ user.billing_plan.name }}
                          </router-link>
                          <span v-else>
                            {{ user.billing_plan ? user.billing_plan.name : '' }}
                          </span>
                        </td>
                        <td>
                          <span v-if="!user.is_pending">
                            <router-link
                              v-if=" canAuthUserAccessAdminFeatures"
                              :to="editPlanAndRoleLink(user)"
                            >
                              <span v-if="user.addons.length">
                                {{ user.addons.map((addon) => {return addon.name}).join(', ') }}
                              </span>
                              <span v-else>
                                No addons
                              </span>
                            </router-link>
                            <span v-else>
                              <span v-if="user.addons.length">
                                {{ user.addons.map((addon) => {return addon.name}).join(', ') }}
                              </span>
                              <span v-else>
                                No addons
                              </span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span v-if="!user.is_pending">
                            <router-link
                              v-if="canAuthUserAccessAdminFeatures"
                              :to="editPlanAndRoleLink(user)"
                            >
                              {{ user.role }}
                            </router-link>
                            <span v-else>
                              {{ user.role }}
                            </span>
                          </span>
                        </td>
                        <td class="td-action">
                          <div class="d-flex flex-column">
                            <a
                              v-if="canAuthUserAccessAdminFeatures"
                              href="#"
                              class="table-action"
                              @click.prevent="editUser(user.id)"
                            >
                              <i class="far fa-edit" />
                              Edit
                            </a>
                            <a
                              v-if="user.is_pending && canAuthUserAccessAdminFeatures"
                              href="#"
                              class="table-action"
                              @click.prevent="sendAnotherInvitation(user.id)"
                            >
                              <i class="far fa-paper-plane" />
                              Resend
                            </a>
                            <a
                              v-if="!user.is_pending && canDeleteUser(user)"
                              href="#"
                              class="table-action"
                              data-disabled="true"
                              @click.prevent="userToBeBlocked = user"
                            >
                              <i class="fas fa-ban" />
                              {{ user.is_blocked ? 'Unblock': 'Block' }}
                            </a>
                            <a
                              v-if="canDeleteUser(user)"
                              href="#"
                              class="table-action"
                              @click.prevent="userToBeDeleted = user"
                            >
                              <i class="fas fa-times" />
                              Delete
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <confirm-dialog
      v-if="isShowCancelBusinessLicensesDialog"
      title="Are you sure you want to cancel?"
      description="Business plan offers useful features and optional upgrades"
      @no="isShowCancelBusinessLicensesDialog = false"
      @yes="cancelBusinessLicenses"
    />

    <confirm-dialog
      v-if="showCancelProLicensesDialog"
      title="Are you sure you want to cancel?"
      description="Pro plan offers useful features and optional upgrades"
      @no="showCancelProLicensesDialog = false"
      @yes="cancelProLicenses"
    />

    <confirm-delete-user-dialog
      v-if="userToBeDeleted"
      :user="userToBeDeleted"
      @close="userToBeDeleted = null"
    />

    <confirm-block-user-dialog
      v-if="userToBeBlocked"
      :user="userToBeBlocked"
      :is-blocked="userToBeBlocked.is_blocked"
      @close="userToBeBlocked = null"
    />

    <request-licenses-modal
      v-if="showRequestLicenses"
      @close-request="showRequestLicenses = false"
    />

    <premium-account
      v-if="isShowPopup"
      :header-text="'This feature is coming soon.'"
      :main-text="'Until then please contact your reseller to request a reduction in your licenses.'"
      :hide-button="true"
      :show-premium="false"
      @close="closeReduceLicenses"
    />
  </main>
</template>

<script>
import {userStore} from '@/store/__STORE_user';
import {billingStore} from '@/store/__STORE_billing';
import DirectLinkMixin from '@/mixins/DirectLinkMixin';
import PlanMixin from '@/mixins/PlanMixin';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconPlusPrimary from '@/components/icons/IconPlusPrimary.vue';
import axios from 'axios';
import FormatMoneyMixin from '@/mixins/FormatMoneyMixin';
import ConfirmDeleteUserDialog from '@/components/ConfirmDeleteUserDialog.vue';
import ConfirmBlockUserDialog from '@/components/ConfirmBlockUserDialog.vue';
import LicenseTable from '@/components/admin/LicenseTable.vue';
import RequestLicensesModal from '@/components/ProvisionedUsers/RequestLicensesModal.vue';
import PremiumAccount from '@/components/PremiumAccount.vue';
import {mainStore} from '@/store/__STORE_main';

export default {
  name: 'UserManagement',
  components: {
    ConfirmDialog,
    ConfirmDeleteUserDialog,
    ConfirmBlockUserDialog,
    IconPlus,
    IconPlusPrimary,
    LicenseTable,
    RequestLicensesModal,
    PremiumAccount,
  },
  mixins: [DirectLinkMixin, PlanMixin, FormatMoneyMixin],
  data() {
    return {
      isShowCancelBusinessLicensesDialog: false,
      showCancelProLicensesDialog: false,
      userToBeDeleted: null,
      userToBeBlocked: null,
      showRequestLicenses: false,
      isShowPopup: false,
    };
  },
  computed: {
    userList: () => userStore.subUsers,
    roleList: () => billingStore.roles,
    authUser: () => userStore.profileData,
    licenses: () => userStore.licenses,
    licensesForTable() {
      if (!this.authUser) {
        return [];
      }
      if (this.isUserProvisioned) {
        return this.licenses;
      } else {
        const arr = [
          {
            key: 'free',
            name: 'Free',
            description: 'free',
            assigned: 0,
            used: this.freeLicensesCount,
            available: 0,
          },
          {
            key: 'launch',
            name: 'Launch',
            assigned: 0,
            used: this.launchLicensesCount,
            available: 0,
          },
          {
            key: 'pro',
            name: 'Pro',
            assigned: 0,
            used: this.proLicensesCount,
            available: this.authUser.spare_pro_licenses,
          },
        ];
        if (this.businessLicensesCount > 0) {
          const element = {
            key: 'business',
            name: 'Business',
            assigned: 0,
            used: this.businessLicensesCount,
            available: this.authUser.spare_business_licenses,
          };
          arr.splice(2, 0, element);
        }
        return arr;
      }
    },
    freeLicensesCount() {
      return this.userList.filter((item) => {
        return item.billing_plan && (item.billing_plan.name === 'Free' || item.billing_plan.name === 'Trial');
      }).length;
    },
    launchLicensesCount() {
      return this.userList.filter((item) => {
        return item.billing_plan && item.billing_plan.name === 'Launch';
      }).length;
    },
    businessLicensesCount() {
      return this.userList.filter((item) => {
        return item.billing_plan && item.billing_plan.name === 'Business';
      }).length;
    },
    proLicensesCount() {
      return this.userList.filter((item) => {
        return item.billing_plan && item.billing_plan.name === 'Pro';
      }).length;
    },
    confirmDeleteMessage() {
      if (!this.userToBeDeleted) {
        return '';
      }

      return 'Are you sure you want to delete the user ' + (this.userToBeDeleted.is_pending ? this.userToBeDeleted.invite_emailed_to : this.userToBeDeleted.email) + '?';
    },
    confirmBlockMessage() {
      if (!this.userToBeBlocked) {
        return '';
      }

      return 'Are you sure you want to ' + (this.userToBeBlocked.is_blocked ? 'unblock' : 'block') + ' the user ' + this.userToBeBlocked.email + '?';
    },
  },
  created() {
    userStore.getUserProfile();
    userStore.getLicenses();
  },
  methods: {
    isSubUserOnNoLicense(user) {
      if (user.billing_plan.name === 'No License') {
        return true;
      }
      return false;
    },
    requestLicenses() {
      if (this.showRequestLicenses) {
        this.showRequestLicenses = false;
        // eslint-disable-next-line
        window.$('#requestLicensesModal').modal('hide');
        this.requestLicenses();
        return;
      }
      this.showRequestLicenses = true;
    },
    closeRequestLicenses() {
      // eslint-disable-next-line
      window.$('#requestLicensesModal').modal('hide');
      this.showRequestLicenses = false;
    },
    reduceLicenses() {
      this.isShowPopup = true;
    },
    closeReduceLicenses() {
      this.isShowPopup = false;
    },
    editPlanAndRoleLink(user) {
      if (this.authUser.is_provisioned) {
        if (user.id === this.authUser.id) {
          return {name: 'pp-self-edit'};
        }
        return {name: 'pp-user-edit', params: {id: user.id, prevRoute: 'user-management'}};
      }
      if (user.id === this.authUser.id) {
        return {name: 'account-plan'};
      }

      return {name: 'plans-role', params: {id: user.id, prevRoute: 'user-management'}};
    },
    canDeleteUser(user) {
      if (
      // Cannot delete the last admin
        user.is_last_admin ||
        // On business plan, cannot delete if current user doesn't have admin privileges
        !this.canAuthUserAccessAdminFeatures ||
        // Cannot self-delete
        (user.id === this.authUser.id) ||
        // On free/launch, cannot delete if current user is not the one who sent the invitation
        (!this.accountHasAdminManagement && (user.invited_by_user_id !== this.authUser.id))
      ) {
        return false;
      }

      return true;
    },
    editUser(id) {
      this.$router.push({path: 'edit-user/' + id});
    },
    sendAnotherInvitation(id) {
      userStore.sendAnotherInvitation({id})
          .then(() => {
            this.$toast.success('Invitation sent!');
          })
          .catch((err) => {
            this.$toast.error('Error!');
          });
    },
    cancelBusinessLicenses() {
      mainStore.setLoading();

      axios({url: '/cancel-spare-business-licenses', method: 'PATCH'})
          .then((resp) => {
            userStore.getUserProfile().then(() => {
              this.$toast.success('Unsubscribed!');
              this.isShowCancelBusinessLicensesDialog = false;
            });
          })
          .catch((err) => {

          });
    },
    cancelProLicenses() {
      mainStore.setLoading();

      axios({url: '/cancel-spare-pro-licenses', method: 'PATCH'})
          .then((resp) => {
            userStore.getUserProfile().then(() => {
              this.$toast.success('Unsubscribed!');
              this.showCancelProLicensesDialog = false;
            });
          })
          .catch((err) => {

          });
    },
  },
};
</script>
