<template>
  <div
    id="modal-calendar-integration"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content">
          <div
            class="f-modal-close"
            @click="$emit('close')"
          />
          <div class="modal-popup">
            <div class="modal-popup-content">
              <confirm-dialog-icon />

              <h6 class="mb-2">
                Confirm delete
              </h6>

              <div>
                Are you sure you want to delete this {{ user.is_pending ? 'invitation' : 'user' }}? This cannot be reversed.
                Please confirm your decision with your password.
              </div>

              <div
                class="form-group mt-1 mb-2"
                :class="{'form-error': errors.password}"
              >
                <input
                  id="password-confirm"
                  v-model="password"
                  class="form-control"
                  type="password"
                  placeholder="Your password..."
                >
                <div
                  v-for="(error, index) in errors.password"
                  :key="index"
                  class="form-error-message"
                >
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="text-center">
              <a
                href="#"
                class="btn btn-outline"
                @click.prevent="$emit('close')"
              >Cancel</a>
              <a
                href="#"
                class="btn btn-primary ml-2"
                @click.prevent="deleteUser"
              >
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfirmDialogIcon from './ConfirmDialogIcon';

import {mainStore} from '@/store/__STORE_main';
import {userStore} from '@/store/__STORE_user';

export default {
  components: {
    ConfirmDialogIcon,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      password: '',
      errors: {},
    };
  },
  created() {
    // eslint-disable-next-line
        $('body').addClass('f-modal-is-open');
  },
  destroyed() {
    // eslint-disable-next-line
        $('body').removeClass('f-modal-is-open');
  },
  methods: {
    deleteUser() {
      this.errors = {};

      if (!this.password.length) {
        this.errors = {password: ['Password is required']};
        return;
      }

      mainStore.setLoading();

      userStore.deleteUser({id: this.user.id, password: this.password})
          .then(() => {
            this.password = '';
            this.$toast.success('Deleted successfully!');
            this.$emit('close');
            window.location.reload();
          })
          .catch((err) => {
            this.password = '';
            const data = err.response.data;
            mainStore.clearStatus();
            if (err.response.status === 422) {
              this.errors = data.errors;
              return;
            }
            this.$toast.error('Error');
          });
    },
  },
};
</script>
