<template>
  <div class="table-content">
    <div
      v-for="(array, type) in licenses"
      :key="array[0].name"
      class="array-container"
    >
      <h5 class="cap-first">
        {{ type }}
      </h5>
      <div
        v-for="element in array"
        :key="element.key"
        class="element-container"
      >
        <div
          class="element-card-left"
        >
          <label>{{ element.name }}</label>
          <p>{{ element.description }}</p>
        </div>
        <div
          class="element-card-right"
        >
          <label>Quantity</label>
          <input
            v-model="element.requested"
            class="form-control"
            type="number"
            max="999999"
            min="0"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {userStore} from '@/store/__STORE_user';

export default {
  name: 'LicenseRequestTable',
  computed: {
    licenses: () => userStore.requestableLicenses,
  },
};
</script>

<style lang="scss" scoped>
  .array-container {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    .element-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px solid #dee2e6;
    padding: 5px;
    margin-bottom: 10px;
    &:last-child {
      // border-bottom: none;
      margin-bottom: 0;
    }
  }
  }
  input[type=number].form-control {
    font-size: 1.6rem;
    width: 80px;
  }

  .cap-first {
    text-transform: capitalize;
    margin-bottom: 15px;
    // font-weight: 800;
  }
  .element-card-left {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    box-sizing: border-box;

    label {
      display: block;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: -0.2px;
      margin-bottom: 10px;
      white-space: nowrap;
    }
  }
  .element-card-right {
    align-items: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    max-width: 90px;
    box-sizing: border-box;
  }
</style>
